* {
  scroll-behavior: smooth;
  font-family: Poppins;
  
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/poppins/Poppins-Medium.ttf) format("truetype");
}
